import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/PostLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Disclaimer: This is personal blogging.`}</p>
    <p>{`The guy sitting next to me at the office has a`}{` `}{`wallpaper
saying “ALWAYS FUCKING DELIVER.” This dude thinks I’m a good developer.
He sometimes asks me for tips or opinions on code-related stuff.
Little does he know, I am a developer-disappointment.
When it comes to delivering, I ain’t close to Jesus.
I’m actually closer to the `}<a parentName="p" {...{
        "href": "https://www.reddit.com/r/poland/comments/9hf0oq/why_is_poczta_polska_so_bad/"
      }}>{`Polish Post`}</a>{`.`}</p>
    <p>{`I’ve started numerous side projects, which I didn’t ship.
A startup idea I was working on with my close friend didn’t get sherlocked,
which is hardly a good thing,
because we hadn’t even started to ship
when it was already too late.`}</p>
    <figure sx={{
      '> p': {
        textAlign: 'center'
      }
    }}>
      <p><img parentName="p" {...{
          "src": "https://blog.crisp.se/wp-content/uploads/2016/01/Making-sense-of-MVP-.jpg",
          "alt": "MVP of a car is not a rolling chassis. It's a skateboard."
        }}></img></p>
      <figcaption>
        <p>{`Illustration by `}<a parentName="p" {...{
            "href": "https://blog.crisp.se/2016/01/25/henrikkniberg/making-sense-of-mvp"
          }}>{`Henrik Kniberg`}</a>{`.
I’ve managed to build a bike or maybe even a motorbike once.
The rest is a garage full of rolling chassis.`}</p>
      </figcaption>
    </figure>
    <p>{`I wanted to build a perfect product, with fantastic user experience, the newest
tech`}{` `}{`stack, and the`}{` `}{`cleanest code, while maintaining some degree of
personal life, passing exams, and completing a thesis.
The thesis included an app, which isn’t`}{` `}{`production-ready (of course),
but took more than five hundred hours of work of`}{` `}{`my partner and me combined.`}</p>
    <p><strong parentName="p">{`Obviously, I was an idiot.`}</strong>{` I bit off more than I could chew.
I started drinking way too much coffee. My, once thick and bushy hair started falling off,
and I’m probably skinnier than I ever was. I don’t remember how to prove a thing about
Van`}{` `}{`Emde`}{` `}{`Boas trees or Fibonacci heaps.`}<br parentName="p"></br>{`
`}{`I can probably do a simple amortized analysis, nothing fancy, though.`}</p>
    <p>{`On the flip side, I learned. I don’t mean computer science. I got really good at
starting`}{` `}{`projects. I learned GraphQL, got a good grasp of the JavaScript ecosystem
with bundlers, React metaframeworks, and the like, but this isn’t the most valuable
piece`}{` `}{`of knowledge I acquired.`}</p>
    <p>{`I learned that `}<strong parentName="p">{`communication is key`}</strong>{`. Unfortunately, in a hard way.`}</p>
    <p>{`I learned that a good chunk of Medium software gurus and best practices
prophets spend more time marketing themselves than actually building software.
Any blogpost claiming that you should follow a few `}<a parentName="p" {...{
        "href": "https://asthasr.github.io/posts/danger-of-simplicity/"
      }}>{`simple`}</a>{` rules to be a good
developer is a hoax. Instead (unfortunately?), we have to strive to understand
the problem at hand, analyze the choices we have, and put in honest work.`}</p>
    <figure sx={{
      img: {
        position: 'absolute',
        top: 0
      }
    }}>
      <a href="https://twitter.com/dylan_koji/status/1120793176472612864">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "698px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "97.3913043478261%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACRElEQVQ4y31Ty47UMBDM/184IXGBCxISQtoDdyQuHJfHBaFlkokdJ07iPJ2XEycZyvY+ZpaBlpVt77i6q6ts73Q6ff92e/frZ900ZVkWZanmBUuvq1630z9i23aEp/f99csXN+/fkYj5vn84+BGL0yzjacZ5OgyD1npZlnVdz8FA4uvhLzrg5+CICAmlx9BlBKsoi6qqQWocp7/7e4YD8FqjT5bltmeKled5wjmAUkr8epW85wYAKzMwJi4rIfK2lX3fy657xvYKeN1MDP3QSpzvAHtqtd9P6Ia8AsZR4AGs6xrgtm2zUmSFAA3k4AJGTdO6iudVPFcdzCc1a0ty3bdy6aQe3bFF61Ep1Ldt1vNBDPjtm1cfbz5ELLFO+WXdyK7vetg0bnYkANwXzedltlvn87Z9+fzpx9fbDPImHAtaI4fgJs8yFscRY8jxHwjSNA2qPnV2mjWtnAw9cyXqpsW5ETFNULGs6r4f5tnMBQoXtKcJKAV7IHRn9X4mzPagNtg68GZ5GzD8BZm734djcKQ0ynPxaM+jSef5w9Z2hjZQR6q1mHal99N/48IqEMAdBGFRt7zqZD+ikrssspNqUshwQzE+5lJqOscbcBzHSZwEgZ8wBrnNkwhDxmLOOaYI7ItJ04wQkgsBqS/A8ACPKbaWOG9QjRJqnUuAx5YQKoTAy6GUFUXhbpsHx3E2CAIaRcZenkYIxiilyLFMrQS8ji5nDM8+QKF7MA6DKngCREzDJAzNlhg8h70YGGNPNmAqtvgC/Afeuzp0sFfWHwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Performance optimized code can be confusing and complicated. Code written to unrealistic deadlines is usually bad, but you only get to have code if your business is still running.",
              "title": "Performance optimized code can be confusing and complicated. Code written to unrealistic deadlines is usually bad, but you only get to have code if your business is still running.",
              "src": "/static/0a673d938a25afe10a5be1c29f8a7639/487bb/tradeoffs-tweets.png",
              "srcSet": ["/static/0a673d938a25afe10a5be1c29f8a7639/e4d6b/tradeoffs-tweets.png 345w", "/static/0a673d938a25afe10a5be1c29f8a7639/1e043/tradeoffs-tweets.png 690w", "/static/0a673d938a25afe10a5be1c29f8a7639/487bb/tradeoffs-tweets.png 698w"],
              "sizes": "(max-width: 698px) 100vw, 698px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </a>
      <figcaption sx={{
        mt: 2
      }}>
  You can't hide from trade-offs behind a bible of best practices.
      </figcaption>
    </figure>
    <p>{`How does my life look at the end of 2019?`}</p>
    <p>{`I’m still failing. I’ve started adding a hamburger menu for this blog.
I wrote an SVG by hand, animated it and made it work without JavaScript.
I`}{` `}{`managed to actually finish the job almost a month later.
This is a failure, but a small one. I don’t need it until I add a “speaking” page with a list of my past meetup
talks and notes for them.`}</p>
    <p>{`I’ve started a `}<a parentName="p" {...{
        "href": "https://github.com/zagrajmy/"
      }}>{`side project`}</a>{` with two friends with whom I played roleplaying games.
Turns out they’re kickass developers and we can learn a lot from each other.
I already learned what Event Storming is. We did two sessions of it and goddamn!
If only I knew it before! I’m feeling pretty confident that we’ll ship the thing.
We’ve set the deadline for a prototype we can start dogfooding for the end of
January. `}</p>
    <p>{`The entire thing is (and is going to be) open source. Including `}<a parentName="p" {...{
        "href": "https://adr.github.io/"
      }}>{`ADRs`}</a>{` with the
significant decisions we make. Our successes and failures will be visible in
the`}{` `}{`open, and we’ll learn from them `}<a parentName="p" {...{
        "href": "https://www.swyx.io/writing/learn-in-public/"
      }}>{`in public`}</a>{`.`}</p>
    <p>{`I’ve got a few non-software side projects I could to write about, including
a`}{` `}{`huge`}{` `}{`one with `}<a parentName="p" {...{
        "href": "https://twitter.com/aleksandrasays"
      }}>{`the girl I like`}</a>{`, but this is the time to stop
writing this blog post and get to work.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      